<template>
  <NavBar @messageFromChild="childMessageRecieved"></NavBar>
  <div class="container-fluid">
    <section class="container child-snap ps-5 d-flex">
      <div class="row">
        <!-- space on left -->
        <div class="col-xxsm"></div>
        <!-- title content -->
        <div class="col">
          <h4
            class="text-start p-1"
            role="button"
            @click="helloButton = !helloButton"
          >
            Hello 👋
          </h4>

          <h1 class="display-1 text-start p-0 huge-text">
            I'm <em>Liam Červenka</em>
          </h1>
          <h5 class="text-start p-1">Aspiring Web-Developer & Filmmaker</h5>
        </div>
        <!-- Animation Next To It -->
        <div class="col" v-if="(!isMobile, helloButton)">
          <DuckBlock></DuckBlock>
        </div>
      </div>
    </section>

    <section class="container child-snap ps-5 d-flex">
      <div class="row">
        <!-- space on left -->
        <div class="col-xxsm"></div>
        <!-- title content -->
        <div class="col" style="font-size: 120%">
          <h2 class="text-start p-1">Here's what I'm up to:</h2>
          <div class="text-start p-1">
            <strong>Coding Projects:</strong>
            <ul>
              <li>
                <a
                  class="list-group-item"
                  role="button"
                  href=" https://github.com/LVaclav/DOT-scraper"
                  target="_blank"
                >
                  <i class="fa-brands fa-github"></i> Dot Scrapper
                </a>
              </li>
              <li>
                <a
                  class="list-group-item"
                  role="button"
                  href="https://github.com/LVaclav/dotExtensionV2 "
                  target="_blank"
                >
                  <i class="fa-brands fa-github"></i> DOT Booking Extension
                </a>
              </li>
            </ul>
          </div>
          <div class="text-start p-1">
            <strong>Film Stuff:</strong>
            <ul>
              <li>
                <a
                  class="list-group-item"
                  role="button"
                  href="https://www.youtube.com/watch?v=QEXsQ5FU6Lw&ab_channel=SienaRebelo"
                  target="_blank"
                >
                  <i class="fa-brands fa-youtube"></i> Money Man - Siena Rebelo
                </a>
              </li>
              <li>
                <a
                  class="list-group-item"
                  href="https://www.youtube.com/watch?v=dmho0xvBWBY&ab_channel=liamCervenka"
                  role="button"
                  target="_blank"
                >
                  <i class="fa-brands fa-youtube"></i> The Granny Grommets -
                  Riding the Silver Surf
                </a>
              </li>
            </ul>
          </div>
          <div class="text-start p-1">
            <strong>Music Stuff:</strong>
            <ul>
              <li>
                <a
                  class="list-group-item"
                  role="button"
                  href="https://open.spotify.com/album/70UbQcDrNckaycGENa2IVu?si=59_q2Wc9T7aP3qosJ6z8Qw"
                  target="_blank"
                >
                  <i class="fa-brands fa-spotify"></i> It wasnt anything
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <section class="container child-snap ps-2 d-flex">
      <div class="row">
        <!-- space on left -->
        <div class="col-xxsm"></div>
        <!-- title content -->
        <div class="col">
          <h2 class="text-start ps-4">Check Me Out</h2>
          <div class="text-start ps-4 pb-3 text-muted fst-italic">
            (what's a digital footprint anyway!)
          </div>
          <div class="row">
            <div class="">
              <a
                v-for="(social, key) in socials"
                v-bind:key="key"
                :class="[
                  'btn',
                  'btn-primary',
                  'btn-lg',
                  'm-1',
                  social.style,
                  { buttonSize: !isMobile },
                ]"
                v-bind:href="social.href"
                target="_blank"
                style="width: 15em"
                ><i :class="social.class"></i> {{ social.name }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="container child-snap ps-2 d-flex align-items-center justify-content-center"
    >
      <div class="row justify-content-center">
        <!-- space on left -->
        <div class="col-xxsm"></div>
        <!-- title content -->
        <div class="col text-center">
          <button v-if="!user" @click="login">Login</button>
          <div v-if="user">
            <h1>My secrets</h1>
            <div>
              <h2>Numbers:</h2>
              <div v-for="(entry, index) in numbers" :key="index">
                <span class="font-weight-bold">{{ entry.name }}: </span>
                <span
                  class="text-primary"
                  @click="copyNumber(entry.number)"
                  style="cursor: pointer"
                  href="javascript:void(0)"
                  >{{ entry.number }}</span
                >
              </div>
              <h2>Pics:</h2>
              <img style="max-width: 30vw" src="../assets/passport-pic.jpeg" />
            </div>
          </div>
          <button v-if="user" @click="logout">Logout</button>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';
import ThreeBlock from '../components/home/ThreeBlock.vue';
import DuckBlock from '../components/home/DuckBlock.vue';
import netlifyIdentity from 'netlify-identity-widget';

export default {
  name: 'App',
  components: {
    NavBar,
    ThreeBlock,
    DuckBlock,
  },

  data() {
    return {
      numbers: [
        { name: 'Tati', number: '+61 430 007 831' },
        { name: 'Mum', number: '+61 424 607 982' },
        { name: 'Alex', number: '+61 409 026 760' },
        { name: 'Kiah', number: '+61 403 239 506' },
        { name: 'Noah', number: '+61 478 177 405' },
      ],
      user: null,
      switchP: true,
      isMobile: false,
      helloButton: false,
      screenWidth: window.innerWidth,
      socials: [
        {
          name: 'Instagram',
          href: 'https://www.instagram.com/liam.cervenka/',
          class: ' fa-brands fa-instagram',
          style: 'btn btn-primary instagram border-0 ',
        },
        {
          name: 'Twitter',
          href: 'https://twitter.com/TiddlesDaTurtle',
          class: 'fa-brands fa-twitter ',
          style: 'btn btn-primary twitter border-0 ',
        },
        {
          name: 'Email',
          href: 'mailto:liam@cervenka.space',
          class: 'fa-solid fa-envelope ',
          style: '',
        },
        {
          name: 'GitHub',
          href: 'https://github.com/LVaclav',
          class: 'fa-brands fa-github ',
          style: 'bg-black border-0 ',
        },
        {
          name: 'Spotify',
          href: 'https://open.spotify.com/user/qow04u2m48bco5miktdmicz4o',
          class: 'fa-brands fa-spotify',
          style: 'spotify border-0 ',
        },
        {
          name: 'Youtube',
          href: 'https://www.youtube.com/channel/UCSRd_nMqPmPJKuF0teAAEAA',
          class: 'fa-brands fa-youtube',
          style: 'youtube border-0 ',
        },
        {
          name: 'SoundCloud',
          href: 'https://soundcloud.com/vaclav0',
          class: 'fa-brands fa-soundcloud',
          style: 'soundcloud border-0 ',
        },
      ],
    };
  },
  watch: {
    switchP() {
      // console.log(this.switchP);
      if (this.switchP == true) {
        this.changeBgWhite();
      } else {
        this.changeBgBryn();
      }
    },
  },
  methods: {
    copyNumber(number) {
      navigator.clipboard.writeText(number).then(
        () => {
          alert('Number copied to clipboard: ' + number);
        },
        () => {
          alert('Failed to copy the number');
        }
      );
    },
    login() {
      netlifyIdentity.open();
      netlifyIdentity.on('login', (user) => {
        this.user = user;
        netlifyIdentity.close();
      });
    },
    logout() {
      netlifyIdentity.logout();
      netlifyIdentity.on('logout', () => {
        this.user = null;
      });
    },
    childMessageRecieved(arg1) {
      // console.log("message from child", arg1);
      this.switchP = arg1;
    },
    changeBgWhite() {
      // console.log("white");
      document.body.className -= ' bryn';
    },
    changeBgBryn() {
      // console.log("bryn");
      document.body.className += ' bryn';
    },
    switchPCheck() {
      // console.log(this.switchP);
      if (this.switchP == true) {
        this.changeBgWhite();
      } else {
        this.changeBgBryn();
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
  computed: {
    isMobile() {
      return this.screenWidth < 600;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    netlifyIdentity.on('init', (user) => {
      this.user = user;
    });
    netlifyIdentity.init();
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
li:hover {
  color: blueviolet;
}
.bryn {
  background-image: url(../assets/bryn.jpeg);
  background-size: 150px;
  background-repeat: repeat;
  background-attachment: fixed;
}

@media (max-width: 576px) {
  .huge-text {
    font-size: 400%;
  }
}

@media (min-width: 577px) and (max-width: 992px) {
  .huge-text {
    font-size: 500%;
  }
}

@media (min-width: 993px) {
  .huge-text {
    font-size: 700%;
  }
}

/* snapping*/
html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}
body {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  scroll-snap-stop: always;
  scroll-padding: 1rem;
}

div {
  scroll-behavior: smooth;
}
.child-snap {
  border-bottom: 1px solid;
  padding: 1rem;
  height: 100vh;
  scroll-snap-align: start;
  text-align: center;
  position: relative;
  scroll-margin: 1rem;
  border-color: rgba(0, 0, 0, 0);
}
.child-snap:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
}
.instagram {
  background: #f09433;
  background: -moz-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  background: linear-gradient(
    45deg,
    #f09433 0%,
    #e6683c 25%,
    #dc2743 50%,
    #cc2366 75%,
    #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
.twitter {
  background: #1d9bf0;
}
.soundcloud {
  background-color: #ff7700;
}
.spotify {
  background: #1ed760;
}
.youtube {
  background: #ff0000;
}
.buttonSize {
  padding: 1em;
}
</style>
