import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LiamView from '../views/LiamView';
import FilmView from '../views/FilmView';
import Protected from '../views/Protected.vue';
import netlifyIdentity from 'netlify-identity-widget';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/liam',
    name: 'liam',
    component: LiamView,
  },
  {
    path: '/film',
    name: 'film',
    component: FilmView,
  },
  {
    path: '/secret',
    name: 'secret',
    component: Protected,
    beforeEnter: (to, from, next) => {
      const currentUser = netlifyIdentity.currentUser();
      if (currentUser) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
