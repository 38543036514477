<template>
  <NavBar></NavBar>
  <div class="container">
    <div class="row">
      <div class="col">
        <h2>Film Projects:</h2>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '../components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
};
</script>

<style></style>
