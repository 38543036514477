<template>
  <div ref="container" style="height: 300px; width: 300px"></div>
</template>

<script>
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { DragControls } from 'three/addons/controls/DragControls.js';

export default {
  name: 'DuckBlock',
  mounted() {
    //create container and scene
    const container = this.$refs.container;

    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    //add renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);

    //add camera
    const camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      10000
    );
    camera.position.set(0, 2, 12);

    //add model (duck)
    var model;
    const loader = new OBJLoader();
    loader.load('duck.obj', (obj) => {
      model = obj;
      scene.add(model);
    });

    //add lights
    const light = new THREE.HemisphereLight(0x0000ff, 0x00ff00, 0.6);
    light.position.set(50, 50, 50);
    scene.add(light);

    window.addEventListener('mousemove', (event) => {
      model.rotation.y = event.clientX / window.innerWidth + 1.5 - 0.5;
      model.rotation.x = event.clientY / window.innerHeight - 0.5;
    });

    //spin model
    const animate = function () {
      requestAnimationFrame(animate);

      renderer.render(scene, camera);
    };
    animate();
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
