<template>
  <NavBar></NavBar>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-sm-3">
        Me:
        <img class="img-thumbnail" src="../assets/liam.png" style="" />
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
};
</script>

<style></style>
