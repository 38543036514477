<template>
  <NavBar></NavBar>
  <div class="container">
    <h1>My secrets</h1>
    <div>
      <H2> Numbers: </H2>
      <div v-for="(entry, index) in numbers" :key="index">
        <span class="font-weight-bold">{{ entry.name }}: </span>
        <span
          class="text-primary"
          @click="copyNumber(entry.number)"
          style="cursor: pointer"
          href="javascript:void(0)"
          >{{ entry.number }}</span
        >
      </div>
      <h2>Pics:</h2>
      <img style="max-width: 30vw" src="../assets/passport-pic.jpeg" />
      <h2>Address:</h2>
      <div>
        <span>Praha:</span>
        <span>Petržílova 3311/33, 143 00 Praha 12-Modřany, Czechia</span>
      </div>
    </div>
  </div>
  <button @click="logout">Logout</button>
</template>

<script>
import NavBar from '../components/NavBar.vue';

export default {
  name: 'App',
  components: {
    NavBar,
  },
  data() {
    return {
      numbers: [
        { name: 'Tati', number: '+61 430 007 831' },
        { name: 'Mum', number: '+61 424 607 982' },
        { name: 'Alex', number: '+61 409 026 760' },
        { name: 'Kiah', number: '+61 403 239 506' },
        { name: 'Noah', number: '+61 478 177 405' },
      ],
    };
  },
  methods: {
    copyNumber(number) {
      navigator.clipboard.writeText(number).then(
        () => {
          alert('Number copied to clipboard: ' + number);
        },
        () => {
          alert('Failed to copy the number');
        }
      );
    },
  },
  logout() {
    netlifyIdentity.logout();
    netlifyIdentity.on('logout', () => {
      this.user = null;
    });
  },
};
</script>

<style></style>
