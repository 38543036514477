<template>
  <div ref="container" style="height: 300px; width: 300px"></div>
</template>

<script>
import * as THREE from 'three';

export default {
  name: 'ThreeBlock',
  mounted() {
    const container = this.$refs.container;

    // Create a Three.js scene
    const scene = new THREE.Scene();
    scene.background = new THREE.Color(0xffffff);

    // Add a camera
    const camera = new THREE.PerspectiveCamera(
      75,
      container.clientWidth / container.clientHeight,
      0.1,
      1000
    );
    camera.position.set(0, 0, 2.5);

    // Add a renderer
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(container.clientWidth, container.clientHeight);
    container.appendChild(renderer.domElement);

    const color3 = new THREE.Color('rgb(32, 33, 36)');
    // Add a  to the scene
    const geometry = new THREE.TorusGeometry();
    const material = new THREE.MeshBasicMaterial({ color: color3 });
    const mesh = new THREE.Mesh(geometry, material);

    scene.add(mesh);

    var geo = new THREE.EdgesGeometry(mesh.geometry); // or WireframeGeometry
    var mat = new THREE.LineBasicMaterial({ color: 0xffffff });
    var wireframe = new THREE.LineSegments(geo, mat);
    mesh.add(wireframe);

    // Render the scene
    const animate = () => {
      requestAnimationFrame(animate);
      mesh.rotation.z += 0.01;
      mesh.rotation.y += 0.01;
      renderer.render(scene, camera);
    };
    animate();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
