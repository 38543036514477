<template>
  <nav class="navbar fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand text"> Liam Cervenka</a>
      <header></header>
      <div>
        <button
          class="btn border-0"
          type="button"
          role="button"
          @click="darkMode()"
        >
          <i v-if="mode == 'light'" class="fa-solid fa-moon"></i>
          <i v-if="mode == 'dark'" class="fa-solid fa-sun"></i>
        </button>
        <button
          class="navbar-toggler border-0"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>

      <div
        class="offcanvas offcanvas-end"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <h5 class="offcanvas-title" id="offcanvasNavbarLabel">
            Stinky Monkey
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>
        <div class="offcanvas-body">
          <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
            <li class="nav-item">
              <router-link class="nav-link active" aria-current="page" to="/"
                >Home</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                class="nav-link active"
                aria-current="page"
                to="/liam"
                >Liam</router-link
              >
            </li>
          </ul>
          <div class="row mx-auto mt-2">
            <div class="justify-content-centerform-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                v-model="this.switch"
                @click="sendMessageToParent()"
              />
            </div>
          </div>
        </div>

        <footer class="text-muted">
          <p>
            <router-link class="nav-link active" aria-current="page" to="/"
              ><i class="fa-regular fa-copyright"></i> Stinky Monkey
              inc</router-link
            >
          </p>
        </footer>
      </div>
    </div>
  </nav>
</template>

<script>
import { lightFraction } from 'plotly.js-dist';

export default {
  name: 'NavBar',
  data() {
    return {
      switch: false,
      mode: 'light',
    };
  },
  methods: {
    darkMode() {
      if (this.mode == 'dark') {
        this.mode = 'light';
      } else {
        this.mode = 'dark';
      }

      document.documentElement.setAttribute('data-bs-theme', this.mode);
    },
    sendMessageToParent() {
      this.$emit('messageFromChild', this.switch);
    },
  },
};
</script>

<style>
.div-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>
